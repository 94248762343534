/* ------------------------------
    // /Checkout
    ------------------------------ */

/*Hide shop*/
.body-checkout .smallcart-content,
.body-checkout .SubMenuArea,
.body-checkout #HeadTop,
.body-checkout #PageFooter,
.body-checkout .LanguageSelection,
.body-checkout .SlideshowContainer,
#mcweb-customerregistration-checkout-registration {
    display: none;
}

.body-checkout #PageHeaderGrid {
    border-bottom: 1px solid #ddd;
}
/*END Hide shop*/

.checkout-search {
    padding: 15px;
}

input#go-back-top {
    font-size: 13px;
}



.checkout {
    padding: 0;
    min-height: 500px;
    padding-bottom: 35px;
}

.checkout .cart-content {
    margin-bottom: 1em;
}


#checkout-popup .modal-body {
    padding: 0 15px;
}

#checkout-popup div,
#checkout-popup label,
#checkout-popup input,
#checkout-popup fieldset {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}


.checkout .carrier-row {
    display: none;
}

.checkout .cart-customer-search {
    padding-top: 0.5em;
}

.checkout .checkout-search {
    box-shadow: 0 1px 2px #000000;
    box-shadow: 0 1px 2px rgba(0,0,0,.05);
    margin: 15px 0;
    padding: 15px 15px 0 15px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.checkout-s-email-header {
    font-weight: bold;
    display: none;
}

.checkout .panel-title {
    display: inline-block;
    text-align: center;
    width: 100%;
    font-size: 11pt;
    margin: -4px 0 7px 0;
}

.icon-togle.pull-left {
    margin-left: 1em;
    margin-top: -8px;
}

.checkout label {
    font-size: 12.8px;
    font-weight: normal;
}

.checkout .price-carrier {
    font-weight: bold;
}

.checkout .cart-content.panel {
    padding: 2px;
}

.checkout .col-sm-bak1 {
    margin-left: -25%;
}

.checkout-title {
    padding: 10px 0 5px 21px;
    margin-top: 0;
}

.header #McPaymentAddOrder-top {
    display: none;
}

/*.checkout .footer .sm-go-back {
  margin-top: 0.5em;
}*/

.checkout #gobackxs,
.checkout #gobacksm {
    text-decoration: underline;
}

.checkout .cart-total.pull-right {
    float: none !important;
    display: block;
    margin: 0 0 -30px 0;
    font-size: 1.1em;
    text-align: center;
}

.cartitem-text {
    text-align: center;
    color: #333;
    font-weight: bold;
    font-size: 1em;
}

.cart-extras label {
    font-size: 9pt;
}

.cart-extras .price-vat-chk,
.cart-extras .msg-to-sale,
.cart-extras .discount-id {
    margin: 0;
}

.cart-extras .checkbox.mva.price-vat-chk {
    display: none;
}

#collapse-cartitems {
    margin-top: 0;
}

.checkout .header .registration-type {
    display: none !important;
}
.checkout .registration-type {
    position: relative;
    top: 10px; 
}
.checkout .registration-type-text {
    display: inline-block;
    padding-right: 0.5em;
    text-transform: uppercase;
    color: #666;
    font-weight: normal;
    font-size: 0.8em;
}


.checkout,
.checkout-left,
.checkout-right {
    background-color: #efefef;
}

.checkout #cart {
    background-color: #ffffff;
}

.checkout .header {
    margin: 0 -5px 5px -5px;
}

.checkout .action-buttons {
    /*display: none;*/
    padding: 0 1.5em 1.5em 1.5em;
}

.checkout .sales-conditions {
    /*display: none;*/
    text-align: center;
}

.checkout .sales-conditions .checkbox {
    margin-bottom: 0;
}

.checkout .sales-conditions label,
.checkout .sales-conditions a {
    font-size: 16px;
    font-weight: bold;
    color: #333;
}
.checkout .sales-conditions a {
    color: #265a88;
}

.checkout .sales-conditions .checkbox input[type="checkbox"] {
    margin-top: 3px !important;
}

.sales-conditions-area .WebPubElement {
  width: 100% !important;
}

.checkout h1 {
    color: #333;
    font-weight: bold;
    text-transform: uppercase;
}

.checkout h2 {
    color: #666;
    font-weight: normal;
    font-size: 1em;
    text-transform: uppercase;
    margin: 0 0 0.3em 0;
}

.checkout .checkout-title {
    margin-top: 0;
}

.checkout .cart-items {
    padding: 10px 0 0 0;
    border-top: 1px dotted #ddd;
}

@media (max-width: 767px) {
    .cart-items .row {
        margin: 0;
    }
}

.checkout .cart-items .row-line.end-line {
    border-bottom: none;
}

.checkout .panel-default > .panel-heading {
    background: none;
}

.checkout #cart .panel {
    -ms-box-shadow: none;
    box-shadow: none;
    border: none;
}

.panel-group {
    margin-bottom: 0;
}

.checkout .panel-heading {
    padding: 7px 0 0 5px;
}

.checkout .panel-group .panel-heading + .panel-collapse > .panel-body {
    border: none;
}

.checkout .cart-totaltxt {
    font-weight: bold;
    text-align: right;
}

.checkout .cart-total-fee-text {
    text-align: right;
    white-space: nowrap;
    padding-left: 0;
}

.checkout .cart-total-fee-amount,
.checkout .cart-totalsum {
    text-align: right;
}

.checkout .cart-total-fee {
    min-height: 0.6em;
}

.checkout .cart-totalsum {
    font-weight: bold;
}

.checkout .cart-total-fee {
    margin-top: -0.5em;
    font-size: 0.8em;
}

.checkout .cart-totaltxt span {
    font-weight: normal;
    font-size: 0.7em;
}

.checkout .cart-totalsum {
    display: inline-block;
    font-size: 1em;
    margin: 0;
    white-space: nowrap;
    padding-left: 0;
}

.checkout .cart-title {
    color: #333;
    font-weight: bold;
}

.checkout .cart-extras {
    border-top: 1px solid #EDEDED;
    margin: 0 12px 0 12px;
    padding-top: 10px !important;
    font-size: 0.8em;
}

@media (max-width: 767px) {
    .msg-to-sale .visible-xs {
        display: inline-block !important;
    }
}

/*.checkout .cart-extras i.glyphicon {
    display: none;
}*/

.checkout .icon-help-circled {
    font-size: 2em;
    color: #333;
    margin: 0.15em 0.2em 0 0.8em;
}

.checkout .cart-content .empty-cart {
    margin-bottom: 30px;
    margin-top: -20px;
    margin-left: -15px;
    text-decoration: underline;
}

#insert-order-alert {
    margin-top: 20px;
    margin-bottom: 10px;
}

.checkout .input-group-addon.mc-btn {
    display: table-cell;
    padding: 6px 12px;
}

.checkout .form-control.zipcode-input.error {
    color: red;
    border-color: red;
}

#checkout-amount {
    padding-top: 1.2em;
}

#checkout-amount .pull-right {
    text-align: center;
    float: none !important;
    padding: 0px 0 16px 0;
}

#checkouttotaltxt {
    display: none;
}

#checkouttotaltxt {
    font-size: 1em;
}

#checkout-total {
    font-size: 2em;
    font-weight: bold;
    padding-left: 5px;
}

.body-checkout .footer-grid {
    padding-top: 0;
}

.sales-conditions.has-error label {
    font-weight: bold;
}

.checkout .footer-inline {
    margin: 10px;
    display: block;
    font-size: 16px;
    font-weight: normal;
}

/* ------------------------------
// Checkout - Extra part
------------------------------ */
.checkout .smart-label div {
    font-size: 10pt;
}

.orgnrssn-conditions .smart-label .col-sm-6 {
    padding-right: 0;
}

.checkout .checkbox.msg-to-sale,
.checkout .checkbox.price-vat-chk {
    margin: 0 0 3px 0;
}

.checkout .checkbox.price-vat-chk {
    float: right;
    margin-right: -3.3em;
    padding: 0;
}

.checkout .checkbox.msg-to-sale {
    padding: 0 0 0 15px;
}

.checkout .sales-message {
    width: 100%;
    height: auto;
}

.sales-message-info {
    font-style: italic;
}
/* ------------------------------
// /END - Checkout - Extra part
------------------------------ */



/* ------------------------------
// /END Checkout
------------------------------ */


/* ------------------------------
    // Cart Customer Search
    ------------------------------ */
.checkout-s-email-input,
.checkout-s-eniro,
.checkout-s-zipcode {
    padding-bottom: 1em;
}

.checkout-s-email-text {
    border-top: 1px solid #ddd;
    background-color: #f5f5f5;
    font-size: 0.8em;
    border-radius: 0 0 4px 4px;
    padding: 10px;
}

.checkout-s-email-skip {
    text-decoration: underline;
    cursor: pointer;
}
/* ------------------------------
// /END - Cart Customer Search
------------------------------ */

#center-popup .submit-dialog-spin {
    font-size: 2em;
    text-align: center;
    margin-top: 1em;
}

#center-popup .submit-dialog-text {
    text-align: center;
    padding-top: 1em;
    margin-bottom: 3em;
}




/* ------------------------------
// - New search and login
------------------------------ */
.checkout-login {
    margin: 30px 0 0 0;
}

.payments .tab-content.tab-radius {
    border-radius: 4px;
}

.checkout-login button.btn {
    margin-top: 15px;
    height: 38px;
}

.checkout-s-password-btn .center-block {
    height: 38px;
}

.checkout-login .express-btn button.btn {
    margin-bottom: 30px;
}

.express-header {
    margin-bottom: 10px;
}

.checkout-login button.btn.new-customer {
    margin-top: 2em;
}

/*@media (min-width: 768px) {
    .new-existing-customer-divider {
        height: 200px;
        border-left: 1px solid #f0f0f0;
        margin-left: 20px
    }
    .new-existing-customer-divider span {
        display: none;
    }
}*/

@media (min-width: 768px) {
    .new-existing-customer-divider {
        position: relative;
        z-index: 1;
        height: 200px;
    }

    .new-existing-customer-divider:before {
        border-left: 1px solid #f0f0f0;
        content: "";
        margin: auto 0;
        position: absolute;
        left: 34%;
        top: 0;
        right: 0;
        bottom: 0;
        height: 95%;
        z-index: -1;
    }
    .new-existing-customer-divider span {
        display: none;
    }
}

@media (max-width: 767px) {
    .new-existing-customer-divider {
        position: relative;
        z-index: 1;
        text-align: center;
        margin: 10px 0px;
    }

    .new-existing-customer-divider:before {
        border-top: 1px solid #f0f0f0;
        content: "";
        margin: 0 auto;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        bottom: 0;
        width: 95%;
        z-index: -1;
    }

    .new-existing-customer-divider span {
        background: #fff;
        padding: 0 0.5em;
        display: inline;
    }
}

.checkout-email-panel {
    padding-bottom: 15px;
}

.checkout-psw-panel {
    padding-bottom: 15px;
}

.checkout-login #error-msg-login,
.checkout-login #error-msg-password {
    margin: 10px 0 0 0;
    font-size: 13px;
}

.checkout-login .form-control.error-input {
    border-color: #FF0000;
    box-shadow: inset 0 1px 1px #000000, 0 0 8px #ff0000;
    box-shadow: inset 0 1px 1px rgba(0,0,0,0.075), 0 0 8px rgba(255,0,0,0.6);
}

.checkout-login i.icon-spinner {
    font-size: 2em;
}


.express-text {
    margin-top: -15px;
}

.login-or {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #999;
    line-height: 0.1em;
    margin: 0 0 30px;
}

.login-or span {
    background: #fff;
    padding: 0 10px;
    color: #999;
    display: none;
}



.express-btn {
    padding-left: 25px;
}

.checkout-forgoten-psw {
    margin-top: 10px;
    cursor: pointer;
}

button.btn.btn-primary.vipps-login-btn {
    background-image: url(/css/images/vipps_login_sq.svg);
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: contain;
    height: 34px;
    border-color: transparent;
    width: 184px;
}

.email-header {
    font-weight: bold;
    font-size: 15px;
    padding-bottom: 7px;
}

.express-header {
    font-weight: bold;
    font-size: 15px;
}

.mc-inline-editor .checkout .checkout-login,
.publish-mode .checkout .checkout-login {
    display: block !important;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #ddd;
    margin-bottom: 20px;
}

.mc-inline-editor .checkout .checkout-psw-panel,
.mc-inline-editor .checkout .checkout-express-panel,
.publish-mode .checkout .checkout-psw-panel,
.publish-mode .checkout .checkout-express-panel {
    display: block !important;
}

.mc-inline-editor .checkout .registration-type,
.publish-mode .checkout .registration-type {
    display: block !important;
}

.mc-inline-editor .checkout .registration-type Button,
.publish-mode .checkout .registration-type Button {
    display: inline-block !important;
}

.checkout .sales-conditions-area {
    padding: 14px 15px;
    overflow-x: hidden;
    overflow-y: scroll;
    height: 200px;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 15px;
}

.publish-mode .checkout .sales-conditions-area {
    display: block !important;
}

.publish-mode .checkout .hidden.customer-reg-pub {
    display: block !important;
    position: relative;
    padding: 40px 0;
}

.publish-mode .checkout .hidden.customer-reg-pub .customer-reg-pub-info {
    min-height: 40px;
    font-style: italic;
    color: brown;
}

.checkout .sales-conditions-area::-webkit-scrollbar {
    -webkit-appearance: scrollbartrack-vertical;
    width: 8px;
}

.checkout .sales-conditions-area::-webkit-scrollbar-thumb {
    border-radius: 8px;
    margin: 6px;
    background-color: #adafaf;
}

.checkout .sales-conditions-area::-webkit-scrollbar-track {
    background-color: transparent;
    width: 8px;
}

.checkout.empty-cart {    
}

.checkout.empty-cart .checkout-area1,
.checkout.empty-cart .checkout-area2,
.checkout.empty-cart .checkout-area3,
.checkout.empty-cart .checkout-area4,
.checkout.empty-cart .checkout-area5,
.checkout.empty-cart .checkout-area6,
.checkout.empty-cart .footer {
    display: none;
}

.checkout.empty-cart .registration-type {
    display: none !important;
}

.checkout.l-out #mcweb-salesconditions-sales-conditions,
.checkout.l-out .checkout-amount {
    display: none !important;
}

.checkout.l-in #mcweb-salesconditions-sales-conditions,
.checkout.l-in .checkout-amount {
    display: block;
}

.checkout.l-out .action-buttons #McPaymentAddOrderbottom {
    visibility: hidden;
}

.checkout.l-in .action-buttons #McPaymentAddOrderbottom {
    visibility: visible;
}

.checkout .sm-go-back.action-buttons {
    display: block;
}


.publish-mode .checkout .preview-content,
.publish-mode .checkout .edit-business,
.mc-inline-editor .checkout .edit-business {
    display: block !important;
}

.publish-mode .preview-content.hide-help .adr-info,
.mc-inline-editor .preview-content.hide-help .adr-info {
    visibility: visible !important;
    position: relative;
    background-color: #eee;
    border-radius: 3px;
    padding-bottom: 75px;
    margin-bottom: 45px;
}

.publish-mode .preview-delivery {
    margin-top: 3px;
}

.mc-inline-editor .preview-edit-add,
.mc-inline-editor .preview-edit-or,
.mc-inline-editor .preview-edit-log {
    display: inline-block !important;
}










/* ------------------------------
// - Address preview
------------------------------ */

.preview-adr,
.checkout-content {
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 30px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.preview-content {
    display: none;
}

.body-in.body-checkout .preview-adr:hover {
    cursor: pointer;
}

.checkout-content {
    display: inline-block;
    width: 100%;
    min-height: 250px;
    padding-top: 50px;
    margin-bottom: 20px;
    padding-left: 30px;
}

.preview-header {
    margin-top: 30px;
    font-weight: bold;
    font-size: 15px;
}

a.preview-not-me.btn-link {
    font-size: 13px;
}

.preview-adr ul {
    list-style: none;
    padding-left: 0;
}

a.preview-help {
    z-index: 9999;
    position: relative;
    height: 34px;
}

.preview-help-icon-hide {
    visibility: hidden;
}

#pub-area-preview-top {
    margin-top: -50px;
}

.preview-content.hide-help .adr-info {
    visibility: hidden;
}
/*.preview-content.show-help .adr-info {
    opacity: 1;
    transition: opacity 1.4s ease-in;
    -ms-transition: opacity 1.4s ease-in; opacity .4s ease-out; opacity .4s ease-out; opacity .4s ease-out;
    -moz-transition: opacity 1.4s ease-in; opacity .4s ease-out; opacity .4s ease-out; opacity .4s ease-out; opacity .4s ease-out;
    -webkit-transition: opacity 1.4s ease-in;
}*/
.preview-content.show-help a.preview-help,
.preview-content.show-help .preview-address-left,
.preview-content.show-help .preview-delivery-right,
.preview-content.show-help .preview-address,
.preview-content.show-help .preview-delivery,
.preview-content.show-help #pub-area-preview-top,
.preview-content.show-help #pub-area-preview-bottom {
    visibility: hidden;
    /*opacity: 0;
    transition: opacity 0.4s ease-in;
    -ms-transition: opacity 0.4s ease-in;
    -moz-transition: opacity 0.4s ease-in;
    -webkit-transition: opacity 0.4s ease-in;*/
}

.preview-content.hide-help a.preview-help,
.preview-content.hide-help .preview-address,
.preview-content.hide-help .preview-delivery,
.preview-content.hide-help #pub-area-preview-top {
    visibility: visible;
    /*opacity: 1;
    transition: opacity 0.4s ease-out;
    -ms-transition: opacity 0.4s ease-out;
    -moz-transition: opacity 0.4s ease-out;
    -webkit-transition: opacity 0.4s ease-out;*/
}

 
.adr-info {
    display: block;
    position: absolute;
    z-index: 99;
    /*background: #fff;*/
    height: 100%;
    padding: 20px;
}

.preview-help .icon-help-circled:before {
    color: #ccc;
}
.preview-help-close {
    font-size: 30px;
    margin-top: -22px;
    color: #ccc;
}

.preview-help-close .icon-cancel:before {
    display: inline-block;
    content: "\00d7";
    font-size: 45px;
    font-weight: 100;
}

button.preview-info-btn.btn-link {
    padding: 30px 110px;
    color: #333;
}
a.preview-info-btn.btn-link {
    padding-top: 50px;
    color: #333;
}

.preview-info-title {
    font-weight: bold;
    display: block;
    text-align: center;
}
.preview-info-text {
    text-align: center;
}

.preview-adr li {
    font-size: 15px;
    padding-left: 25px;
    line-height: 24px;
    position: relative;
    margin-bottom: 0;
    color: #555;
    white-space: normal;
}

.preview-adr li:before {
    font-family: "multicase-icons";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    position: absolute;
    left: -.1em;
    top: 2px;
    color: #999;
    font-size: 17px;
}

.preview-address-title,
.preview-delivery-title {
    font-weight: bold;
    font-size: 15px;
}

.preview-address {
    margin-top: -20px;
}
.preview-address.preview-address-left {
    margin-top: 0;
}
.preview-delivery {
    margin-top: 10px;
}

.preview-company:before {
    content: "\f275"
}
.preview-name:before {
    content: "\e810";
}
.preview-street-address:before {
    content: "\e80d";
    margin-left: -1px;
    font-size: 20px !important;
}

.preview-email:before {
    content: "\f0e0";
}
.preview-phone:before {
    margin-left: -3px;
    margin-top: -3px;
    content: "\f10b";
    font-size: 26px !important;
}
.preview-org:before {
    content: "\f1ad";
    margin-left: 1px;
}


.preview-delivery-name:before {
    content: "\e810";
}
.preview-delivery-address:before {
    content: "\e80d";
    margin-left: -1px;
    font-size: 20px !important;
}
.preview-delivery-contact:before {
    content: "\e80c";
}
.preview-delivery-phone:before {
    margin-left: -3px;
    margin-top: -3px;
    content: "\f10b";
    font-size: 26px !important;
}



.preview-edit {
    top: -40px;
    display: block;
    height: 0;
    text-align: center;
    width: 100%;
    position: relative;
    color: #0072cc;
    text-decoration: none;
    background: transparent;
}

.preview-edit span {
    background: #fff;
    display: inline-block;
    z-index: 1;
    margin: 0 -2px;
}

.preview-edit .preview-edit-add span {
    padding-left: 16px;
    padding-right: 3px;
}
.preview-edit .preview-edit-log span {
    padding-right: 16px;
    padding-left: 3px;
}
.preview-edit-or {
    color: #777;
}

.preview-edit-change {
    padding: 0 16px;
    background: #fff;
}

.preview-edit-change span:hover {
    padding: 0 16px;
    text-decoration: underline;
}

/* ------------------------------
// - END Address preview
------------------------------ */





/*==================================================
=            Bootstrap 3 Media Queries             =
==================================================*/

/*==========  Mobile First Method  ==========*/

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) {
}

/* Extra Small Devices, Phones */
@media only screen and (min-width: 480px) {
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {
    .checkout .header {
        min-height: 48px;
    }

    .checkout .checkout-search {
        box-shadow: none;
        padding: 0 15px 5px 0;
        background: none;
        border: none;
    }

    .checkout-title {
        padding: 10px 0 0 15px;
    }

    .checkout .panel-title {
        margin: -4px 0 30px 0;
    }

    .checkout .cart-customer-search {
        padding-top: 1em;
    }

    .checkout .registration-type-text {
        display: inline-block;
        padding-right: 0.5em;
        text-transform: uppercase;
        font-size: 1em;
        margin: 0 0 10px 0;
    }

    .checkout-s-email-text {
        font-size: 11pt;
        padding: 5px 15px;
        background: none;
        border: none;
        margin-top: 0;
        color: #666;
    }

    .checkout .icon-help-circled {
        font-size: 2.6em;
        color: #333;
        margin: 0.06em 4px 0 4px;
    }

    .checkout .cart-items {
        padding: 5px 10px 0 10px;
    }

    .checkout .header {
        margin: 1.2em 0 -0.5em 0;
    }

    .checkout .action-buttons {
        padding: 0.8em 1.125em 0 1.125em;
    }

    .checkout .panel-title {
        display: block;
        text-align: left;
        margin: 0;
    }

    .checkout .panel-heading {
        padding: 10px 15px 0 15px;
    }

    .icon-togle.pull-left {
        margin: 0;
    }

    #McPaymentAddOrder-top {
        margin: 0;
    }

    .footer #McPaymentAddOrder-top {
        margin-top: 1em;
    }

    .checkout .sm-go-back {
        padding-left: 0;
    }

    .checkout #go-back-sm {
        text-align: left;
    }

    .checkout .cart-total.pull-right {
        float: right !important;
        margin: 0;
        font-size: 1.2em;
        text-align: left;
    }

    .checkout .panel-heading .icon-togle:after {
        margin: 0 5px 0 0;
        font-size: 1em;
    }

    .cartitem-text {
        text-align: left;
    }

    .cart-extras label {
        font-size: 12.8px;
    }

    .cart-items .row-line {
        margin: 0 -10px 4px -10px;
    }

    #collapse-cartitems {
        margin-top: 0;
    }

    .col-amount-xs .input-group {
        min-width: 0;
        margin-top: 1.2em;
    }

    .col-amount-xs .input-group .form-control {
        max-width: none;
        min-width: 3em;
        padding: 5px 0;
    }

    .checkout .checkbox.price-vat-chk {
        padding: 0 15px 0 15px;
    }

    .checkout .checkbox.msg-to-sale {
        padding: 0 15px 0 0;
    }

    #checkout-amount .pull-right {
        text-align: left;
        float: right !important;
        padding: 0;
    }

    /**/
    .footer .checkout-amount-text, 
    .footer #checkout-total {
        display: none;
    }

    .checkout .cart-extras {
        font-size: 1em;
    }

    .preview-delivery {
        margin-top: -35px;
    }


    /* ------------------------------
    // - New search and login
    ------------------------------ */
    .checkout-login button.btn.btn-email {
        margin-top: 0;
    }

    .checkout-login .express-btn button.btn {
        margin-bottom: 0;
    }

    .express-header {
        margin-bottom: 0;
    }

    .checkout-express-panel {
        padding-right: 40px;
    }

    .checkout .sales-conditions {
        text-align: left;
    }
}
/* END - Small Devices, Tablets (min-width : 768px) */

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
    .checkout,
    .checkout-left,
    .checkout-right {
        padding-bottom: 2em;
    }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {
}

/*==================================================
=  END       Bootstrap 3 Media Queries             =
==================================================*/