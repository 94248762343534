.checkout-receipt {
	padding-top: 30px;
	padding-bottom: 30px;
}

.checkout-receipt h1 {
	color: #333;
	font-weight: normal;
	text-transform: uppercase;
}

.checkout-receipt h2 {
	color: #333;
	font-weight: bold;
	font-size: 1.2em;
	/*text-transform: uppercase;*/
	margin: 30px 0 10px 0;
}

.checkout-receipt p {
	margin: 5px 0;
	font-size: 14px;
}

.receipt-links a {
	color: #2a7ab5;
}
.checkout-receipt .receipt-links {
	margin-top: 20px;
}

.checkout-receipt .receipt-info {
	margin-top: 20px;
}

.checkout-receipt .receipt-welcome-back {
	margin-top: 20px;
}

/* Santander start */
.SantanderKvittering {
    margin-top: 10px;
    border: solid 1px black;
    padding: 10px;
}

.SantanderPayeverKvittering {
    margin-top: 10px;
    border: solid 1px black;
    padding: 10px;
}

.SantanderKvitteringLinkBox {
    padding-left: 10px;
    padding-top: 25px;
    padding-bottom: 25px;
}

.SantanderKvitteringLink {
    font-size: large;
    padding: 15px;
    background-color: #387711;
    color: white;
}

a.SantanderKvitteringLink:Hover {
    color: white;
}
/* Santander slutt */

/*==================================================
=            Bootstrap 3 Media Queries             =
==================================================*/

/*==========  Mobile First Method  ==========*/

/* Custom, iPhone Retina */
@media only screen and (min-width : 320px) {
}

/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) {
}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
		
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
}

/*==================================================
=  END       Bootstrap 3 Media Queries             =
==================================================*/
