/* ------------------------------
	// LoginView popup 
	------------------------------ */

#center-popup .modal-body {
    padding: 0;
    overflow-y: visible;
}

#center-popup .lightbox-close {
    width: 1.2em;
    height: 1.2em;
    margin-top: -0.5em;
    margin-right: -1.0em;
    background: url("/css/Master/close.png") no-repeat scroll 0 0 #000000;
    background: url("/css/Master/close.png") no-repeat scroll 0 0 rgba(0, 0, 0, 0);
    font-size: 2.5em;
    cursor: pointer;
    text-align: right;
    z-index: 99999;
    right: 15px;
    position: absolute;
}

#center-popup div,
#center-popup label,
#center-popup input,
#center-popup fieldset {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    /*overflow: visible;*/
}

#login {
    padding: 0;
}

#login .login-username,
#login .login-password {
    margin-bottom: 0;
}

.login-left {
    /*margin: 0 1.125em 1.125em 1.125em;*/
    /*padding: 0 0 0.5em 0;*/
    border-bottom: 1px solid #cccccc;
}

.login-header h2 {
    font-size: 1.5em;
    padding: 0.5em 0 0.5em 0;
}

.login-customer-text p {
    font-size: 1.1em;
}

.form-horizontal .login-username .control-label,
.form-horizontal .login-password .control-label,
.form-horizontal .password-username .control-label {
    text-align: left;
    font-weight: normal;
    font-size: 1.2em;
}

#login .checkbox input#remember_me {
    margin-top: 2px;
}

.login-remember label {
    padding-left: 0;
}

.form-horizontal .login-password a.control-label,
.form-horizontal .password-cancel a.control-label {
    display: block;
    text-decoration: underline;
    padding-top: 1em;
    text-align: left;
}

.form-horizontal .login-password a.control-label:hover,
.form-horizontal .password-cancel a.control-label:hover {
    text-decoration: none;
    cursor: pointer;
}

#login .username-icon,
#login .password-icon,
#forgotten-password .username-icon {
    position: absolute;
    top: 1.2em;
    left: 1.5em;
    color: #999999;
}

.icon-user,
.icon-key {
    font-size: 1.2em;
}

#error-msg-login,
#error-msg-password {
    font-size: 1.2em;
    color: red;
    /*margin-bottom: 20px;*/
}

.login-warning {
    min-height: 1.5em;
}

.password-btn {
    padding-top: 1em;
}

.login-btn {
    min-width: 50%;
}

@media only screen and (max-width : 767px) {
    .vipps-login-pretext,
    .vipps-login-btn-container {
        text-align: center;
    }
}

button.btn.btn-primary.vipps-login-btn {
    background-image: url(/css/images/vipps_login_sq.svg);
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: contain;
    height: 38px;
    border-color: transparent;
    width: 207px;
}

.login-customer {
    padding: 0 1.125em 2em 1.125em;
}

.login-customer-btn {
    padding: 10px 0 40px 0;
}

.login-customer-btn .btn {
    width: 100%;
}

#center-popup .center-placeholder:after {
    clear: both;
    display: table;
    content: " ";
}

#center-popup .center-placeholder {
    overflow: visible;
}


/* ------------------------------
	// Forgotten password
	------------------------------ */

#forgotten-password #fp-secondary {
    display: none;
}

.password-header h2 {
    font-size: 1.5em;
    padding-bottom: 1em;
}

.password-info p {
    padding-bottom: 0.8em;
    font-size: 1.1em;
}




/*==================================================
=            Bootstrap 3 Media Queries             =
==================================================*/

/*==========  Mobile First Method  ==========*/

/* Custom, iPhone Retina */
@media only screen and (min-width : 320px) {
}

/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) {
}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {

    .center-placeholder {
        background-color: #fff;
        border-radius: 6px;
        overflow: auto;
    }

    #center-popup .lightbox-close {
        width: 1em;
        height: 1em;
    }

    #login .form-group {
        margin-bottom: 15px;
    }

    .login-left {
        margin: 0;
        border-right: 1px solid #cccccc;
        padding: 0 0 0 2em;
        border-bottom: none;
        margin-bottom: 0;
    }

    .login-header h2 {
        padding: 1em 0 0.5em 0;
    }

    .form-horizontal .login-password a.control-label {
        font-size: 1em;
        padding-top: 8px;
    }

    .password-btn {
        padding-top: 1.2em;
    }
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {

    #login {
        padding: 1em;
    }

    #forgotten-password fieldset {
        padding-left: 2em;
    }

    .login-customer {
        padding: 0 2.5em;
    }

    .login-forgoten-psw {
        padding: 0 1.125em 0 0;
    }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
}

/*==================================================
=  END       Bootstrap 3 Media Queries             =
==================================================*/
